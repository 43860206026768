<template>
  <div class="misc-wrapper">

    <!-- 標題 -->
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary">
        曠世國際有限公司
      </h2>
    </b-link>

    <!-- 內容 -->
    <div
      v-if="state"
      class="misc-inner p-2 p-sm-3"
    >
      <div class="w-100 text-center">

        <!-- 驗證狀態 -->
        <h2 class="mb-1">
          {{ state.success=== true ? '帳號已啟用!🔓': '帳號啟用失敗🚫' }}
        </h2>

        <!-- 驗證說明 -->
        <p class="mb-2">
          {{ state.success === true ? '感謝您註冊我們的帳號，現在已經可以使用我們的服務了!': state.message + '，請回官網重新註冊' }}
        </p>

        <!-- 導向按鈕 -->
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="{ name: 'auth-login' }"
        >
          前往登入畫面
        </b-button>

        <!-- 背景圖 -->
        <b-img
          fluid
          :src="downImg"
        />
      </div>
    </div>

  </div>
</template>

<script>
// API
import store from '@/store'
import router from '@/router'
import axios from '@axios'
// UI
import { BLink, BImg, BButton } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    BLink,
    BImg,
    BButton,
    VuexyLogo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized-dark.svg'),
      state: null, // {}
    }
  },
  created() {
    this.tokencheck()
  },
  methods: {
    // 開通信箱檢查(可優化)
    tokencheck() {
      axios(`/api/tokencheck/${this.urlToken.token}`, {
        method: 'POST',
      })
        .then(response => {
          this.state = response.data
          if (response.data.success) {
            const userData = JSON.parse(localStorage.getItem('userData'))
            userData.power = '使用者'
            localStorage.setItem('userData', JSON.stringify(userData))
            store.commit('app/UPDATE_USERDATA_STATE', userData)
          }
        })
    },
  },
  setup() {
    const urlToken = router.currentRoute.params
    return {
      urlToken,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
